// If you don't want to use TypeScript you can delete this file!
import React, { useState } from "react"
import { PageProps, Link, graphql } from "gatsby"
import { useForm } from "react-hook-form";
import Layout from "../components/layout"
import { useLoggedIn, useSupabase } from "../hooks"

const NewBlog: React.FC<PageProps> = ({
  location,
}) => {
  const { isLoggedIn, credentials } = useLoggedIn()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm();
  const supabase = useSupabase(credentials)

  const postBlog = async (stuff) => {
    if (!hasSubmitted) {
      const { data, error } = await supabase
        .from('posts')
        .insert(
          stuff
        )
      console.log("new", { data, error })
      if (data) {
        console.log({ data })
        setHasSubmitted(data[0].id)
      }
    } else {
      console.log(`upserting ${hasSubmitted}`)
      const { data, error } = await supabase
        .from('posts')
        .upsert(
          {
            ...stuff,
            id: hasSubmitted
          }
        )
      console.log({ data, error })
    }

  }
  const onSubmit = data => {
    console.log(data);
    postBlog(data)
  }


  if (
    !isLoggedIn && typeof window !== "undefined"
  ) {
    window.location.replace('/login');
  }


  return (
    <Layout title="Login" location={location}>


      {isLoggedIn && (
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* register your input into the hook by invoking the "register" function */}
            <div>
              <input defaultValue="" placeholder="title" {...register("title", { required: true })} />
            </div>
            <div>
              <input {...register("date")} placeholder="date" type="datetime-local" />
            </div>
            {/* errors will return when field validation fails  */}
            {errors.exampleRequired && <span>This field is required</span>}
            <div>
              <textarea {...register("body")} placeholder="body" />
            </div>
            <input type="submit" value="post" />

            {/* Published Yes/no radio */}
          </form>
        </div>
      )}

    </Layout>
  )
}

export default NewBlog

